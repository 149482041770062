<script>

</script>

<style>
  svg.background {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  svg.gray-blob {
    left: 0;
  }
  svg.blue-blob {
    right: 0;
    width: 46.8%;
  }

  img {
    position: absolute;
    top: 155px;
    right: -5.5%;
    width: 46.6%;
  }

  .popup {
    z-index: 1;
    position: absolute;
    height: 80px;
    width: 278px;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .popup.one {
    top: 338px;
    right: 368px;
  }
  .popup.two {
    top: 428px;
    right: 229px;
  }
  @media only screen and (max-width: 1248px) {
    .popup.one {
      right: 200px;
    }
    .popup.two {
      right: 89px;
    }
  }
  @media only screen and (max-width: 1032px) {
    .popup.one {
      top: 220px;
      right: 100px;
    }
    .popup.two {
      top: 310px;
      right: 10px;
    }
  }

  .popup-text {
    font-size: 14px;
    line-height: 22px;
    opacity: 0.3;
  }

  .popup svg {
    margin-right: 12px;
  }
  .popup.one svg {
    height: 36px;
    width: 36px;
  }
  .popup.two svg {
    height: 56px;
    width: 56px;
  }
</style>

<svg class="background gray-blob" viewBox="0 0 1320 632">
  <path
    fill="#FAFAFA"
    d="M0,0 L1320,0 C1540,257.333333 1540,436 1320,536 C1100,636 660,657.333333
    0,600 L0,0 Z"
    id="back" />
</svg>
<svg class="background blue-blob" viewBox="0 0 622 690">
  <defs>
    <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-4">
      <stop stop-color="#1D6CCC" offset="0%" />
      <stop stop-color="#2487FF" offset="100%" />
    </linearGradient>
  </defs>
  <path
    d="M68,0 L618,0 C713.265555,171.647923 713.265555,314.012506 618,427.09375
    C475.101668,596.715616 203.248367,773.91953 68,631.338983
    C-22.1655782,536.285285 -22.1655782,325.838957 68,0 Z"
    fill="url(#linearGradient-4)" />
</svg>
<div class="popup one">
  <svg viewBox="0 0 36 36">
    <defs>
      <filter
        x="-10.6%"
        y="-36.9%"
        width="121.2%"
        height="173.8%"
        filterUnits="objectBoundingBox"
        id="filter-1">
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <polygon
        id="path-2"
        points="0.000385714286 0.00013 17.3571429 0.00013 17.3571429 17.55
        0.000385714286 17.55" />
    </defs>
    <circle id="Oval" fill="#1FB589" cx="18" cy="18" r="18" />
    <g id="Group-3" transform="translate(9, 9.45)">
      <mask id="mask-3" fill="white">
        <use xlink:href="#path-2" />
      </mask>
      <g id="Clip-2" />
      <path
        d="M7.88952857,3.98853 L7.88952857,4.93623 C6.97731429,5.26968
        6.31131429,6.14783 6.31131429,7.17938 C6.31131429,8.49173
        7.38102857,9.57268 8.67895714,9.57268 C9.1251,9.57268 9.46774286,9.91848
        9.46774286,10.37023 C9.46774286,10.82263 9.1251,11.16778
        8.67895714,11.16778 C8.23152857,11.16778 7.88952857,10.82263
        7.88952857,10.37023 L6.31131429,10.37023 C6.31131429,11.40178
        6.97731429,12.28058 7.88952857,12.61403 L7.88952857,13.56173
        L9.46774286,13.56173 L9.46774286,12.61403 C10.3799571,12.28058
        11.0453143,11.40178 11.0453143,10.37023 C11.0453143,9.05853
        9.97624286,7.97758 8.67895714,7.97758 C8.23152857,7.97758
        7.88952857,7.63113 7.88952857,7.17938 C7.88952857,6.72763
        8.23152857,6.38183 8.67895714,6.38183 C9.1251,6.38183 9.46774286,6.72763
        9.46774286,7.17938 L11.0453143,7.17938 C11.0453143,6.14783
        10.3799571,5.26968 9.46774286,4.93623 L9.46774286,3.98853
        L7.88952857,3.98853 Z M8.67895714,1.59523 C12.6081,1.59523
        15.7793143,4.80168 15.7793143,8.77513 C15.7793143,12.74793
        12.6081,15.95438 8.67895714,15.95438 C4.74917143,15.95438
        1.57795714,12.74793 1.57795714,8.77513 C1.57795714,4.80168
        4.74917143,1.59523 8.67895714,1.59523 L8.67895714,1.59523 Z
        M8.67895714,0.00013 C3.89545714,0.00013 0.000385714286,3.93848
        0.000385714286,8.77513 C0.000385714286,13.61113 3.89545714,17.55013
        8.67895714,17.55013 C13.4618143,17.55013 17.3575286,13.61113
        17.3575286,8.77513 C17.3575286,3.93848 13.4618143,0.00013
        8.67895714,0.00013 L8.67895714,0.00013 Z"
        id="Fill-1"
        fill="#FFFFFF"
        mask="url(#mask-3)" />
    </g>
  </svg>
  <p class="popup-text">A new donor just pledged!</p>
</div>
<div class="popup two">
  <svg viewBox="0 0 36 36">
    <rect
      id="Rectangle"
      fill="#DEA74E"
      x="0"
      y="0"
      width="36"
      height="36"
      rx="5" />
    <path
      fill="#fff"
      transform="translate(9, 9)"
      d="M6,0 C4.76660175,0 3.75,1.02544177 3.75,2.26956522 C3.75,2.53553027
      3.80566425,2.78671894 3.890625,3.02608696 L0,3.02608696 L0,7.56521739
      L0.75,7.56521739 L0.75,17.4 L17.25,17.4 L17.25,7.56521739 L18,7.56521739
      L18,3.02608696 L14.109375,3.02608696 C14.1943358,2.78671894
      14.25,2.53553027 14.25,2.26956522 C14.25,1.02544177 13.2333983,0 12,0
      C10.6875,0 9.796875,1.00475543 9.2109375,1.84402174 C9.13183575,1.95631831
      9.0703125,2.06565916 9,2.175 C8.9296875,2.06565916 8.86816425,1.95631831
      8.7890625,1.84402174 C8.203125,1.00475543 7.3125,0 6,0 Z M6,1.51304348
      C6.46875,1.51304348 7.078125,2.02133152 7.546875,2.6951087
      C7.661133,2.86059783 7.6435545,2.8694635 7.734375,3.02608696 L6,3.02608696
      C5.5751955,3.02608696 5.25,2.69806367 5.25,2.26956522 C5.25,1.84106677
      5.5751955,1.51304348 6,1.51304348 Z M12,1.51304348 C12.4248045,1.51304348
      12.75,1.84106677 12.75,2.26956522 C12.75,2.69806367 12.4248045,3.02608696
      12,3.02608696 L10.265625,3.02608696 C10.3564455,2.8694635
      10.338867,2.86059783 10.453125,2.6951087 C10.921875,2.02133152
      11.53125,1.51304348 12,1.51304348 Z M1.5,4.53913043 L16.5,4.53913043
      L16.5,6.05217391 L9.75,6.05217391 L9.75,5.29565217 L8.25,5.29565217
      L8.25,6.05217391 L1.5,6.05217391 L1.5,4.53913043 Z M2.25,7.56521739
      L15.75,7.56521739 L15.75,15.8869565 L9.75,15.8869565 L9.75,8.32173913
      L8.25,8.32173913 L8.25,15.8869565 L2.25,15.8869565 L2.25,7.56521739 Z" />
  </svg>
  <p class="popup-text">25 donors have upgraded their annual gift this week.</p>
</div>
<img
  src="assets/nickel-apps.png"
  alt="Stylized Nickel apps running on tablet and mobile phone." />
