<script>
  import CircleIcon from "../common/CircleIcon.svelte";
</script>

<style>
  .wrapper-valueprops {
    width: 100%;
    margin-top: 148px;
    padding: 212px 212px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 1248px) {
    .wrapper-valueprops {
      padding: 152px 122px 0px;
    }
  }
  @media only screen and (max-width: 1032px) {
    .wrapper-valueprops {
      padding: 72px 122px 0px;
    }
  }

  .value-prop {
    height: 100%;
    margin-right: 56px;
  }

  .value-prop:last-of-type {
    margin-right: 0px;
  }

  .vp-title {
    font-size: 26px;
    line-height: 34px;
  }

  .vp-body {
    font-size: 16px;
    line-height: 24px;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .wrapper-valueprops {
      width: 100%;
      margin-top: 0;
      padding: 0 20px;
      flex-direction: column;
    }

    .value-prop {
      height: auto;
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
</style>

<div class="wrapper-valueprops">
  <div class="value-prop">
    <CircleIcon icon="foam-finger" />
    <h3 class="vp-title">Modern Donor Experience</h3>
    <p class="vp-body">
      The age of email is dead. We connect with the world on our mobile devices,
      we pay our bills, book hotels and flights, order food and of course,
      socialize. Why should the experience supporting our favorite college teams
      be any different?
    </p>
  </div>
  <div class="value-prop">
    <CircleIcon icon="revenue" />
    <h3 class="vp-title">Drive New Revenue Streams</h3>
    <p class="vp-body">
      You have thousands of fans out there that have never financially supported
      your athletic department. Why? Too far from campus? Not interested in
      making a generic donation? Maybe they don’t feel their contribution makes
      a difference? Nickel addresses them all.
    </p>
  </div>
  <div class="value-prop">
    <CircleIcon icon="crm" />
    <h3 class="vp-title">Get More From Your Existing Technologies</h3>
    <p class="vp-body">
      An athletic department stack can be a bit messy. Between your CRM (or
      two), your ticketing solution, and all the other piece-meal technology
      add-ons, wrangling all of your data and services can be overwhelming. The
      Nickel DXM™ platform allows you to soothe your data beast.
    </p>
  </div>
</div>
