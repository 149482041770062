<script>

</script>

<style>
  img {
    height: auto;
    width: 100%;
  }
</style>

<img
  src="assets/nickel-apps-mobile.png"
  alt="Stylized Nickel apps running on tablet and mobile phone." />
