<script>
  import TopLink from "../common/TopLink.svelte";
  import SocialIcon from "./SocialIcon.svelte";
</script>

<style>
  .wrapper-footer {
    height: 60px;
    width: 100vw;
    padding: 0px 162px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222;
    user-select: none;
  }
  @media only screen and (max-width: 1248px) {
    .wrapper-footer {
      padding: 0 72px;
    }
  }

  .footer-links,
  .social-links {
    display: flex;
  }

  .footer-link {
    margin: 0px;
    margin-right: 16px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    opacity: 0.3;
    cursor: pointer;
  }
  .footer-link:active {
    opacity: 0.2;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .wrapper-footer {
      height: 105px;
      padding: 0px 40px;
      flex-direction: column;
      justify-content: center;
    }

    .footer-links {
      margin-bottom: 20px;
    }
  }
</style>

<div class="wrapper-footer">
  <div class="footer-links">
    <TopLink href="/contact">
      <p class="footer-link">Contact</p>
    </TopLink>
    <p class="footer-link">Support</p>
    <TopLink href="/legal">
      <p class="footer-link">Legal</p>
    </TopLink>
    <TopLink href="/privacy">
      <p class="footer-link">Privacy</p>
    </TopLink>
  </div>
  <div class="social-links">
    <SocialIcon icon="facebook" />
    <SocialIcon icon="twitter" />
    <SocialIcon icon="instagram" />
  </div>
</div>
