<script>
  import TopLink from "../common/TopLink.svelte";
</script>

<style>
  .wrapper-cta-pod {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 148px;
    padding: 0px 162px;
    margin-top: -4px; /* for ghost pixels in Technologies section */
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(-90deg, #2487ff 0%, #1d6ccc 100%);
    color: #fff;
  }
  @media only screen and (max-width: 1248px) {
    .wrapper-cta-pod {
      padding: 0 72px;
    }
  }

  .cta-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cta-title {
    margin: 0px;
    margin-bottom: 12px;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
  }

  .cta-body {
    margin: 0 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
  }
  @media only screen and (max-width: 882px) {
    .cta-body {
      margin: 0 20px 40px;
    }
  }

  :global(.cta-link) {
    width: 130px;
  }

  .cta-button {
    width: 130px;
    padding: 0;
    border-radius: 5px;
    outline: none;
    border: 2px solid #fff;
    height: 60px;
    color: #fff;
    background-color: transparent;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
  .cta-button:active {
    opacity: 0.9;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .wrapper-cta-pod {
      height: 282px;
      padding: 0px 20px;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>

<div class="wrapper-cta-pod">
  <div class="cta-text">
    <h3 class="cta-title">Getting started is easy</h3>
    <p class="cta-body">Answer a few questions and we'll do the rest</p>
  </div>
  <TopLink class="cta-link" href="/start">
    <button class="cta-button">SEE HOW</button>
  </TopLink>
</div>
